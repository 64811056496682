import React from 'react'
import {
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps,
} from '@chakra-ui/react'

export const AccordionItem: React.FC<
  React.PropsWithChildren<Pick<AccordionItemProps, 'as' | 'sx' | 'children'>>
> = ({ sx, ...props }) => (
  <ChakraAccordionItem {...props} sx={{ border: 'none', ...sx }} />
)
