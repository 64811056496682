import type { Moment } from 'moment'
import { HourStatus, TradingHourDay } from '@scentregroup/shared-graphql'
import { DateTime, isToday, isTomorrow } from './date-helpers'
import { TradingHour } from './parse-opening-hours/types'

export const TODAY_STRING = 'Today'
export const YESTERDAY_STRING = 'Yesterday'
export const TOMORROW_STRING = 'Tomorrow'

export const formatTimeAMPM = (dt: Moment): string => dt.format('h[.]mma')

export const formatDay = (dt: Moment): string => dt.clone().format('ddd')
export const formatDayNumber = (dt: Moment): string => dt.clone().format('D')
export const formatMonth = (dt: Moment): string => dt.clone().format('MMM')
export const formatDayAndMonth = (dt: Moment): string => dt.format('D MMM')
export const formatYear = (dt: Moment): string => dt.clone().format('YYYY')
export const formatISOWithTime = (dt: Moment): string =>
  dt.clone().format('YYYY-MM-DDTHH:mm')

// Mon 19th Feb, Wed 21st Feb ...
export const formatTradingHoursDate = (dt: Moment): string =>
  `${formatDay(dt.clone())} ${formatDayNumber(dt.clone())} ${formatMonth(
    dt.clone()
  )}`

// Today
// Tomorrow
// Mon 19th, Wed 21st ...
// Mon 19th Feb, Wed 21st Feb ...
export const formatHumanDate = (
  dt: Moment,
  opts: { timeZone?: string; includeMonth?: boolean } = {}
): string => {
  if (isToday(dt, opts.timeZone)) {
    return TODAY_STRING
  }
  if (isTomorrow(dt, opts.timeZone)) {
    return TOMORROW_STRING
  }

  let str = `${formatDay(dt.clone())} ${formatDayNumber(dt.clone())}`

  if (opts.includeMonth) {
    str = `${str} ${formatMonth(dt.clone())}`
  }

  return str
}

// 9:13am, 2:25pm ...
export const formatTime = (dt: Moment): string =>
  dt.clone().format('h:mma').toLowerCase()

// expects date as String ISO 8601 format e.g. 2018-05-14T08:00:00.000Z
export const howLongAgo = (date: string, timeZone: string): string => {
  const today = DateTime.local({ timeZone }).endOf('day')
  const dt = DateTime.fromISO(date, { timeZone })
  const duration = DateTime.moment.duration(dt.diff(today))

  if (duration.days() > -1) {
    return TODAY_STRING
  }

  if (duration.days() > -2) {
    return YESTERDAY_STRING
  }

  return duration.humanize(true)
}

export const formatTimes = (
  tradingHourDays: TradingHourDay[],
  timeZone: string
): (TradingHour & { openingTime: string; closingTime: string })[] =>
  tradingHourDays.map(tradingHourDay => ({
    ...tradingHourDay,
    hourStatus: tradingHourDay.hourStatus ?? HourStatus.Normal,
    description: tradingHourDay.description ?? '',
    openingTime: formatTime(
      DateTime.fromISO(`${tradingHourDay.date}T${tradingHourDay.openingTime}`, {
        timeZone,
        format: 'HH:mm',
      })
    ),
    closingTime: formatTime(
      DateTime.fromISO(`${tradingHourDay.date}T${tradingHourDay.closingTime}`, {
        timeZone,
        format: 'HH:mm',
      })
    ),
  }))
