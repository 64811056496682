import querystring, { ParsedUrlQuery } from 'querystring'

import { trustedHostnameSuffixes } from '../constants'

export const transformParsedUrlQuery = (
  parsedQuery: ParsedUrlQuery
): string[][] =>
  Object.entries(parsedQuery).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map(v => [key, v])
    }
    return [[key, value ?? '']]
  })

export function rewriteParsedUrlQuery(
  query: ParsedUrlQuery,
  params: Record<string, string | string[] | undefined>
): ParsedUrlQuery {
  const keys = Object.keys(params)
  const entries = transformParsedUrlQuery(query).reduce((entries, [k, v]) => {
    if (keys.includes(k) && entries.find(([key]) => k === key)) {
      return entries
    }
    const value = keys.includes(k) ? params[k] : v
    if (value === undefined) {
      return entries
    }
    if (Array.isArray(value)) {
      return [...entries, ...value.map(val => [k, val])]
    }
    return [...entries, [k, value]]
  }, [] as string[][])
  return querystring.parse(new URLSearchParams(entries).toString())
}

const routeMatcher = new RegExp(
  '(\\[\\[\\.\\.\\.(?<optionalCatchAll>\\w+)\\]\\])|(\\[\\.\\.\\.(?<catchAll>\\w+)\\])|(\\[(?<dynamic>\\w+)\\])',
  'g'
)
/**
 * Get a URL path with query/search parameters from a
 * router object (eg. from Next.js useRouter).
 *
 * Will replace dynamic route parameters and include the URL search params.
 *
 * Optional `overrides` parameter allows rewriting the dynamic
 * route parameters and search parameters.
 *
 * Set an override value to `undefined` to remove a search parameter.
 *
 * Returns a string to use as a link to the route.
 */
export function getAbsolutePathFromRouter(
  router: {
    route: string
    basePath: string
    query: ParsedUrlQuery
  },
  queryOverrides?: Record<string, string | string[] | undefined>,
  queryExtra?: Record<string, string | string[]>
): string {
  const { route, basePath } = router
  const query = { ...router.query, ...queryExtra }
  const override = queryOverrides
    ? rewriteParsedUrlQuery(query, queryOverrides)
    : query
  const e = encodeURIComponent
  const catchAllReplacer = (k: string): string => {
    const a = override && override[k]
    if (Array.isArray(a)) {
      return a.map(e).join('/')
    }
    if (typeof a === 'string') {
      return e(a)
    }
    return ''
  }
  const dynamicReplacer = (k: string): string =>
    (override && override[k] && e(String(override[k]))) || ''
  const matches = Array.from(route.matchAll(routeMatcher))
  const path = matches
    .reduce(
      (s, m) =>
        s.replace(
          m[0],
          (m.groups &&
            (catchAllReplacer(m.groups.optionalCatchAll) ||
              catchAllReplacer(m.groups.catchAll) ||
              dynamicReplacer(m.groups.dynamic))) ||
            ''
        ),
      route
    )
    .replace(/\/$/, '')

  const entries = transformParsedUrlQuery(override)
  const searchParams = new URLSearchParams(entries)
  for (const { groups } of matches) {
    if (!groups) {
      continue
    }
    searchParams.delete(
      groups.optionalCatchAll || groups.catchAll || groups.dynamic
    )
  }
  if (Array.from(searchParams).length > 0) {
    return `${basePath}${path}?${searchParams.toString()}`
  }
  return `${basePath}${path}`
}

const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i')

export function isTrustedUrl(urlString: string): string {
  // Relative URLs are implicitly trusted. This to support
  // relative URLs in featured links
  if (!absoluteUrlRegex.test(urlString)) {
    return urlString
  }
  const url = new MaybeURL(urlString)
  const isTrusted = trustedHostnameSuffixes.some(
    suffix => url.hostname?.endsWith(suffix)
  )
  return isTrusted ? urlString : ''
}

// Convenience wrapper around URL for parsing data that may or may not be a valid URL
// or even exist. Read-only - it makes no sense to assign to part of a non-existant URL,
// use the base class for write operations.
export class MaybeURL {
  private url: URL | undefined
  constructor(
    maybeUrlString: string | undefined | null,
    maybeUrlBase?: string | null
  ) {
    try {
      this.url = maybeUrlString
        ? new URL(maybeUrlString, maybeUrlBase ?? undefined)
        : undefined
    } catch {
      this.url = undefined
    }
  }
  get href(): string | undefined {
    return this.url?.href
  }
  get hash(): string | undefined {
    return this.url?.hash
  }
  get host(): string | undefined {
    return this.url?.host
  }
  get hostname(): string | undefined {
    return this.url?.hostname
  }
  get origin(): string | undefined {
    return this.url?.origin
  }
  get password(): string | undefined {
    return this.url?.password
  }
  get pathname(): string | undefined {
    return this.url?.pathname
  }
  get port(): string | undefined {
    return this.url?.port
  }
  get protocol(): string | undefined {
    return this.url?.protocol
  }
  get search(): string | undefined {
    return this.url?.search
  }
  get username(): string | undefined {
    return this.url?.username
  }
  get searchParams(): URLSearchParams | undefined {
    return this.url?.searchParams
  }
  // The toString() method should always return a string I think
  toString(): string {
    return this.url?.toString() ?? ''
  }
  toJSON(): string | undefined {
    return this.url?.toJSON()
  }
}

// Type assertion that MaybeURL is indeed a string | undefined equivalent of URL
type StrictPartial<T> = { [K in keyof T]: T[K] | undefined }
type RelaxedURL = StrictPartial<Omit<InstanceType<typeof URL>, 'toJSON'>>

new MaybeURL(null) satisfies RelaxedURL
