import { HubResponsiveArray } from '@hub/design-system-base'
import { ResizeMode } from '..'
import { useCurrent } from './use-current'
import makeImageUrl, { ImageFormat } from './make-image-url'
import { SGPageTemplateImage } from '@scentregroup/shared/types/page-templates'

// series of growing image widths to allow the browser to choose the best
const availableSourceSizes = [100, 200, 300, 450, 600, 750, 900, 1072]
const availableDevicePixelRatios = [1, 2]

const availableSources = availableSourceSizes.flatMap(size =>
  availableDevicePixelRatios.map(ratio => ({ size, ratio }))
)

export function useSrcSet(
  preferredImage: SGPageTemplateImage,
  ratio?: HubResponsiveArray<string | number>,
  resizeMode?: HubResponsiveArray<ResizeMode>,
  imageFormat?: HubResponsiveArray<ImageFormat>
): string {
  const currentRatio = useCurrent(ratio)
  const currentResizeMode = useCurrent(resizeMode)
  const currentImageFormat = useCurrent(imageFormat)
  return availableSources
    .map(srcOption => {
      const src = makeImageUrl(
        preferredImage,
        currentRatio,
        currentResizeMode,
        srcOption.size,
        srcOption.ratio,
        imageFormat === 'none' ? 'none' : currentImageFormat
      )
      // we can't specify both dpr and width in srcset so we just multiply size and ratio
      return `${src} ${srcOption.size * srcOption.ratio}w`
    })
    .join(', ')
}
